import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from 'components/DatePickerField';
import { useFiltersSearch } from 'utils';
import { LEASE_PROVIDERS_TYPE } from 'common';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
}));

export const typeDateReport = [
  {
    value: 'approval date',
    label: 'Approval date'
  },
  {
    value: 'sale date',
    label: 'Sale date'
  },
];

export const Filter = props => {
  const { open, onClose, dataReloadInit, marketingTreeList, className } = props;

  const classes = useStyles();

  const { register, watch, setValue, handleSubmit } = useFormContext();
  const { setFromDate, setToDate, setOID, setPID, setSID, setEID, setType, setLeaseProvider, setStoreType } = useFiltersSearch();


  const clearValues = useCallback(() => {
    setValue('fromDate', null, true);
    setValue('toDate', null, true);
    setValue('oid', '', true);
    setValue('pid', '', true);
    setValue('sid', '', true);
    setValue('eid', '', true);
    setValue('type', '', true);
    setValue('leaseProvider', '', true);
    setValue('storeType', '', true);
  }, [setValue]);

  register({ name: 'oid', type: 'custom' });
  register({ name: 'pid', type: 'custom' });
  register({ name: 'sid', type: 'custom' });
  register({ name: 'eid', type: 'custom' });
  register({ name: 'type', type: 'custom' });
  register({ name: 'leaseProvider', type: 'custom' });
  register({ name: 'storeType', type: 'custom' });
  register({ name: 'fromDate', type: 'custom' });
  register({ name: 'toDate', type: 'custom' });

  const {oid, pid, sid, eid, type, leaseProvider/*, storeType*/} = watch();

  const [pidList, setPidList] = useState([]);
  const [sidList, setSidList] = useState([]);
  const [eidList, setEidList] = useState([]);

  useEffect(function() {
    if (oid) setPidList(marketingTreeList.find((element) => element.oid === +oid)?.pid_list?.map((elem => ({name: elem.name, value: elem.pid}))));
  }, [oid,marketingTreeList, setPidList]);

  useEffect(function() {
    if (pid) setSidList(marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.sid_list?.map((elem) => ({name: elem.name, value: elem.sid})));
  }, [oid, pid, marketingTreeList, setSidList]);

  useEffect(function() {
    if (sid) setEidList(marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.sid_list?.find((elem) => elem.sid === +sid)?.eid_list.map((elem) => ({name: elem.name, value: elem.eid})));
  }, [oid, pid, sid, marketingTreeList, setEidList]);

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ oid, pid, sid, eid, fromDate, toDate, leaseProvider, storeType }) => {
          setOID(oid);
          setPID(pid);
          setSID(sid);
          setEID(eid);
          setType(type);
          setFromDate(fromDate);
          setToDate(toDate);
          setLeaseProvider(leaseProvider);
          setStoreType(storeType);
          dataReloadInit();
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Offer ID"
                  name="oid"
                  onChange={({currentTarget}) => {
                    setValue('oid', currentTarget.value, true);
                    setValue('pid', '', true);
                    setValue('sid', '', true);
                    setValue('eid', '', true);
                    //setPidList(marketingTreeList.find((element) => element.oid === +currentTarget.value)?.pid_list?.map((elem => ({name: elem.name, value: elem.pid}))));
                  }}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={oid}
                  variant="outlined"
                >
                  <option
                    key=""
                    value=""
                  />
                  {marketingTreeList?.map((elem) => ({name: elem.name, value: elem.oid}))?.map((option, index) => (
                    <option
                      key={'tree_' + option.name+ '_' + option.value + index}
                      value={option.value}
                    >
                      {option.name} : {option.value}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  disabled={oid===undefined || oid===''}
                  fullWidth
                  label="Program ID"
                  name="pid"
                  onChange={({currentTarget}) => {
                    setValue('pid', currentTarget.value, true);
                    setValue('sid', '', true);
                    setValue('eid', '', true);
                    //setSidList(marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +currentTarget.value)?.sid_list?.map((elem) => ({name: elem.name, value: elem.sid})));
                  }}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={pid}
                  variant="outlined"
                >
                  <option
                    key=""
                    value=""
                  />
                  {oid && pidList?.map((option, index) => (
                    <option
                      key={'pid_' + option.value + index}
                      value={option.value}
                    >
                      {option.name} : {option.value}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  disabled={pid===undefined || pid===''}
                  fullWidth
                  label="Source ID"
                  name="sid"
                  onChange={({currentTarget}) => {
                    setValue('sid', currentTarget.value, true);
                    setValue('eid', '', true);
                    // setEidList(marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.sid_list?.find((elem) => elem.sid === +currentTarget.value)?.eid_list.map((elem) => ({name: elem.name, value: elem.eid})));
                  }}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={sid}
                  variant="outlined"
                >
                  <option
                    key=""
                    value=""
                  />
                  {pid && sidList?.map((option, index) => (
                    <option
                      key={'sid_' + option.value + index}
                      value={option.value}
                    >
                      {option.name} : {option.value}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  disabled={sid===undefined || sid===''}
                  fullWidth
                  label="Extra ID"
                  name="eid"
                  onChange={({currentTarget}) => setValue('eid', currentTarget.value, true)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={eid}
                  variant="outlined"
                >
                  <option
                    key=""
                    value=""
                  />
                  {sid && eidList?.map((option, index) => (
                    <option
                      key={'eid_' + option.value + index}
                      value={option.value}
                    >
                      {option.name} : {option.value}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="Lease Provider"
                name="leaseProvider"
                onChange={({currentTarget}) => {
                  setValue('leaseProvider', currentTarget.value, true);
                  if (currentTarget.value?.length > 0) setValue('storeType', '', true);
                }}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={leaseProvider}
                variant="outlined"
              >
                <option value="" />
                {LEASE_PROVIDERS_TYPE.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>

            {/*<div className={classes.contentSectionContent}>*/}
            {/*  <div className={classes.formGroup}>*/}
            {/*    <TextField*/}
            {/*      className={classes.field}*/}
            {/*      fullWidth*/}
            {/*      label="Store Type"*/}
            {/*      name="storeType"*/}
            {/*      onChange={({currentTarget}) => {*/}
            {/*        setValue('storeType', currentTarget.value, true);*/}
            {/*        if (['furniture', 'electronics'].includes(currentTarget.value)) setValue('leaseProvider', '', true);*/}
            {/*      }}*/}
            {/*      select*/}
            {/*      // eslint-disable-next-line react/jsx-sort-props*/}
            {/*      SelectProps={{native: true}}*/}
            {/*      value={storeType}*/}
            {/*      variant="outlined"*/}
            {/*    >*/}
            {/*      <option value=""/>*/}
            {/*      <option value={'furniture'}>Furniture</option>*/}
            {/*      <option value={'electronics'}>Electronics</option>*/}
            {/*    </TextField>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="Date Used"
                name="type"
                onChange={({currentTarget}) => setValue('type', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={type}
                variant="outlined"
              >
                {/*<option value="" />*/}
                {typeDateReport.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'fromDate'}
                  label={'From Date* '}
                  maxDateFieldName={'toDate'}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'toDate'}
                  label={'To Date* '}
                  minDateFieldName={'fromDate'}
                />
              </div>
            </div>

          </div>
          {/*<div>*/}
          {/*  {JSON.stringify(watch())}*/}
          {/*</div>*/}
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  dataReloadInit: PropTypes.func.isRequired,
  marketingTreeList: PropTypes.array
};
