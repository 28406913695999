import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  colors, CardActions, Button
} from '@material-ui/core';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import axios_v2 from 'utils/axios_v2';
import { toastHttpError } from 'utils/common';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  statusItem: {
    marginRight: theme.spacing(1)
  },
  itemText: {
    marginTop: 8,
    marginBottom: 8
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ServicesStatus = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();

  const statusColors = {
    true: colors.green[600],
    false: colors.red[600]
  };

  const [servicesOnline, setServicesOnline] = useState([]);

  useEffect(() => {
    let mounted = true;

    const fetchServicesOnline = () => {
      axios_v2().get('/services_online').then(response => {
        if (mounted) {
          setServicesOnline(response?.data?.data || []);
        }
      }).catch(response => {
        toastHttpError(response);
      });
    };

    fetchServicesOnline();

    return () => {
      mounted = false;
    };
  }, []);


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      id="services_status"
    >
      <CardHeader
        subtitle={`${servicesOnline.length} in total`}
        title="Services Status"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {servicesOnline.map((service, i) => (
            <ListItem
              divider={i < servicesOnline.length}
              key={i}
            >
              <ListItemText
                className={classes.itemText}
                primary={service.name}
              />

              <div className={classes.statusItem}>
                {!service.success ?
                  <WarningIcon
                    fontSize="small"
                    style={{ color: colors.red[600] }} />
                  : <CheckCircleRoundedIcon
                    fontSize="small"
                    style={{ color: colors.green[600] }} />}
              </div>

              <Typography
                style={{ color: statusColors[service.success] }}
                align="left"
                variant="h6"
              >
                {service.success ? 'Online' : 'Offline'}
              </Typography>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={() => history.push('/current_states')}
        >
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

ServicesStatus.propTypes = {
  className: PropTypes.string
};

export default ServicesStatus;
