import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  CardActions,
  CardHeader,
  Divider,
  Link
} from '@material-ui/core';

import { ParamsPagination, usePagination, useFiltersSearch } from 'utils';
import { Label, ReconciliationFilter } from 'components';
import axios from 'utils/axios.js';
import notifyError, { notifySuccess } from 'utils/common';
import ClearIcon from '@material-ui/icons/Clear';
import { Search } from '../../../../../components/SearchBar/components';
import {
  DEFAULT_LEASE_PROVIDER,
  LEASE_PROVIDERS_TYPE,
  LEASE_PAYMENT_STATUS
} from '../../../../../common';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  tableWrapper: {
    height: 'calc(100vh - 230px)',
    overflow: 'auto'
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:705px)']: {
      marginTop: theme.spacing(2)
    }
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap'
  },
  statusButton: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: '4px',
    top: 4,
    minWidth: 70
  }
}));

const ResultRow = props => {
  const { dataReloadInit, item } = props;

  const classes = useStyles();

  const changeLeasePaymentStatus = useCallback(
    e => {
      const { id, status } = e.currentTarget.dataset;
      if (id) {
        try {
          axios()
            .put('reports/update_lease_payment_status', { id, status })
            .then(res => {
              if (res.status === 200) {
                notifySuccess(
                  `Order ID #${id} Lease payment status updated successfully`
                );
                dataReloadInit();
              }
            });
        } catch (e) {
          console.log(e);
          notifyError('Something Went Wrong');
          return Promise.reject('Request Failed');
        }
      }
    },
    [dataReloadInit]
  );

  const getNameLeaseProviderInfo = useCallback(lease_provider => {
    return (
      LEASE_PROVIDERS_TYPE.find(elem => elem.value === lease_provider)?.label ||
      DEFAULT_LEASE_PROVIDER.label
    );
  }, []);

  const getColorLeaseProviderInfo = useCallback(lease_provider => {
    return (
      LEASE_PROVIDERS_TYPE.find(elem => elem.value === lease_provider)?.color ||
      DEFAULT_LEASE_PROVIDER.color
    );
  }, []);

  const getNameLeasePaymentStatusInfo = useCallback(lease_payment_status => {
    return LEASE_PAYMENT_STATUS.find(
      elem => elem.value === lease_payment_status
    )?.label;
  }, []);

  const getColorLeasePaymentStatusInfo = useCallback(lease_payment_status => {
    return LEASE_PAYMENT_STATUS.find(
      elem => elem.value === lease_payment_status
    )?.color;
  }, []);

  return (
    <TableRow key={item.id}>
      {item.date_lease_initiation && (
        <TableCell className={classes.whiteSpace}>
          {moment(item.date_lease_initiation).format('DD MMM YYYY   h:mm a')}
        </TableCell>
      )}
      <TableCell>
        <Link component={RouterLink} target="_blank" to={'/orders/' + item.id}>
          {item.id}
        </Link>
      </TableCell>
      <TableCell>
        <Link component={RouterLink} target="_blank" to={'/orders/' + item.id}>
          {item.bc_id}
        </Link>
      </TableCell>
      <TableCell>
        <div className={classes.address}>
          {' '}
          {item?.customer?.first_name || ''} {item?.customer?.last_name || ''}
        </div>
        <div>phone: +{item?.customer?.phone || ''}</div>
        <div>{item?.customer?.email || ''}</div>
      </TableCell>
      <TableCell>
        {item?.lease_provider && (
          <Label
            className={classes.fulfillment_tag}
            color={getColorLeaseProviderInfo(item?.lease_provider)}
            variant="contained">
            {getNameLeaseProviderInfo(item?.lease_provider) || ''}
          </Label>
        )}
      </TableCell>
      <TableCell>
        {item?.lease_total_due || item.lease_total_due === 0
          ? `$${parseFloat(item.lease_total_due)?.toFixed(2)}`
          : ''}
      </TableCell>
      <TableCell>
        {item?.lease_payment_status && (
          <Label
            className={classes.fulfillment_tag}
            color={getColorLeasePaymentStatusInfo(item?.lease_payment_status)}
            variant="contained">
            {getNameLeasePaymentStatusInfo(item?.lease_payment_status)}
          </Label>
        )}
      </TableCell>
      <TableCell>
        <div className={classes.statusButton}>
          {LEASE_PAYMENT_STATUS.map(({ value, label }, index) => (
            <Button
              className={classes.headButton}
              color="secondary"
              data-id={item.id}
              data-status={value}
              disabled={value === item.lease_payment_status}
              id={'view_button_' + index}
              key={index}
              onClick={changeLeasePaymentStatus}
              size="small"
              variant={'outlined'}>
              {label}
            </Button>
          ))}
        </div>
      </TableCell>
    </TableRow>
  );
};

const Results = props => {
  const {
    className,
    count,
    list,
    isProgressIn,
    dataReloadInit,
    ...rest
  } = props;

  const classes = useStyles();

  const { page, limit } = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  const {
    queryText,
    fromDate,
    toDate,
    leaseProvider,
    leasePaymentStatus,
    setFromDate,
    setToDate,
    setQueryText,
    setLeaseProvider,
    setLeasePaymentStatus
  } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate]);

  const clearSearch = useCallback(() => {
    setQueryText('');
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearLeaseProvider = useCallback(() => {
    setLeaseProvider('');
    dataReloadInit();
  }, [dataReloadInit, setLeaseProvider]);

  const clearLeasePaymentStatus = useCallback(() => {
    setLeasePaymentStatus('');
    dataReloadInit();
  }, [dataReloadInit, setLeasePaymentStatus]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={clsx(classes.labelWrap)} ref={labelWrapRef}>
        {queryText && (
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            name="btn-search"
            onClick={clearSearch}
            size="small"
            variant="contained">
            {' '}
            {`Search: [${queryText}] `}
          </Button>
        )}

        {fromDate && (
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            name={'btn-from-date'}
            onClick={clearFromDate}
            size="small"
            variant="contained">
            {' '}
            {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
          </Button>
        )}
        {toDate && (
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            name={'btn-to-date'}
            onClick={clearToDate}
            size="small"
            variant="contained">
            {' '}
            {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
          </Button>
        )}
        {leaseProvider && (
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            name={'btn-lease-provider'}
            onClick={clearLeaseProvider}
            size="small"
            variant="contained">
            {' '}
            {` Lease Provider: [${leaseProvider}]`}
          </Button>
        )}
        {leasePaymentStatus && (
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            name={'btn-lease-payment-status'}
            onClick={clearLeasePaymentStatus}
            size="small"
            variant="contained">
            {' '}
            {` Lease Payment Status: [${leasePaymentStatus}]`}
          </Button>
        )}
      </div>
      <Card>
        <CardHeader
          action={
            <ReconciliationFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
            />
          }
          className={classes.cardHeader}
          ref={cardHeaderRef}
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInit} />
            </div>
          }
        />

        <Divider />
        <CardContent
          className={classes.content}
          style={{
            height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`
          }}>
          <div className={classes.tableWrapper} style={{ height: '100%' }}>
            {list.length > 0 && !isProgressIn && (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>Lease Initiation Date</TableCell>
                    <TableCell className={classes.tableCell}>
                      DoradoDigital&nbsp;ID
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      ID
                    </TableCell>
                    <TableCell className={classes.bodyMessage}>
                      Customer
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Lease Provider
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Lease Total Due
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Lease Payment Status
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Change Lease Payment Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list?.map((item, index) => (
                    <ResultRow
                      dataReloadInit={dataReloadInit}
                      item={item}
                      key={index}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
            {isProgressIn && (
              <div className={classes.spinnerRoot}>
                <CircularProgress size={60} />
              </div>
            )}
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {count} Charges found
            {count > 0 &&
              '  Page ' + (page + 1) + ' of ' + Math.ceil(count / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination isProgressIn={isProgressIn} recordsCount={count} />
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  dataReloadInit: PropTypes.func.isRequired,
  isProgressIn: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired
};

Results.defaultProps = {
  list: [],
  count: 0,
  isProgressIn: false
};
export default Results;
