import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from 'components/DatePickerField';
import { useFiltersSearch, useGetRiskProfileListFull, useGetLeaseProvidersFull } from 'utils';
import { LEASE_PROVIDERS_TYPE, PAYMENT_STATUS_OPTIONS } from 'common';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  // contentSection: {
  //   paddingTop: theme.spacing(1, 0),
  //   paddingBottom: theme.spacing(1, 0),
  // },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(1, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  // field_2: {
  //   paddingTop: '18.5px',
  //   paddingBottom: '18.5px',
  // },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  divider: {
    marginBottom: '10px',
    marginTop: '10px',
  },
}));

export const cancelledSubStatuses = [
  'preliminary',
  'final_id_verification',
  'final_customer_request',
  'lease_terminated'
];

export const cancelledSubStatusMasquerade = {
  'preliminary': 'Preliminary',
  'final_id_verification': 'Final ID Verification',
  'final_customer_request': 'Final By Customer Request',
  'lease_terminated': 'Lease Terminated'
};

export const paymentSubStatusOptions = [
  {
    value: 'Canceled',
    label: 'Canceled'
  },
  {
    value: 'Returned',
    label: 'Returned'
  },
  {
    value: 'Price change',
    label: 'Price change'
  },
  {
    value: 'Out of stock',
    label: 'Out of stock'
  },
  {
    value: 'BB not canceled',
    label: 'BB not canceled'
  },
  {
    value: 'Duplicate',
    label: 'Duplicate'
  },
  {
    value: 'Lease',
    label: 'Lease Provider Error'
  },
  {
    value: 'Reprocess',
    label: 'Reprocess'
  },
  {
    value: 'Shipping',
    label: 'Available'
  },
  {
    value: 'Delivery address updated',
    label: 'Delivery address updated'
  },
  {
    value: 'Approve Required',
    label: 'Approval Required'
  },
  {
    value: 'Waiting For Customer ID',
    label: 'Waiting For Customer ID'
  },
  {
    value: 'Verified',
    label: 'Auto Approval'
  },
  {
    value: 'Manual Approval',
    label: 'Manual Approval'
  },
  {
    value: 'Verified Unexpected',
    label: 'Suspected Fraud'
  },
  {
    value: 'Suspicious IP Location',
    label: 'Suspicious IP Location'
  },
  {
    value: 'Jumio Success',
    label: 'Jumio Success'
  },
  {
    value: 'Jumio Failed',
    label: 'Jumio Failed'
  },
  {
    value: 'Veriff Success',
    label: 'Veriff Success'
  },
  {
    value: 'Veriff Failed',
    label: 'Veriff Failed'
  },
  {
    value: 'Elderly Person',
    label: 'Elderly Person'
  },
  {
    value: 'Blacklist',
    label: 'Blacklist'
  },
  {
    value: 'Hotlist Product',
    label: 'Hotlist Product'
  },
  {
    value: 'Waiting For SMS Delivery',
    label: 'Waiting For SMS Delivery'
  },
  {
    value: 'Added Lease',
    label: 'Added Lease'
  },
];

export const paymentDataTypeOptions = [
  {
    value: 'blank_application_id',
    label: 'Blank Application ID'
  },
  {
    value: 'Pickup Delay',
    label: 'Pickup Delay'
  },
  {
    value: 'Shipped',
    label: 'Shipped'
  },
  {
    value: 'home_delivery',
    label: 'Home Delivery'
  }
];

export const Filter = props => {
  const { open, onClose, dataReloadInit, className } = props;

  const classes = useStyles();

  const { register, watch, setValue, handleSubmit } = useFormContext();
  const { setFromDate, setToDate, setStatus, setSubStatus, setDataType, setLeaseProvider, setCancelledSubStatus, setCostMin, setCostMax } = useFiltersSearch();

  const [showCancelledSubStatus, setShowCancelledSubStatus] = React.useState(false);

  const dispatch = useDispatch();
  const { riskProfileList } = useGetRiskProfileListFull();
  const { leaseProvidersList } = useGetLeaseProvidersFull();
  const [requestBody, setRequestBody] = useState({});

  const clearValues = useCallback(() => {
    setValue('paymentStatus', '', true);
    setValue('subStatus', '', true);
    setValue('dataType', '', true);
    setValue('fromDate', null, true);
    setValue('toDate', null, true);
    setValue('leaseProvider', '', true);
    setValue('cancelledSubStatus', '', true);
    setValue('costMin', '', true);
    setValue('costMax', '', true);
  }, [setValue]);

  register({ name: 'paymentStatus', type: 'custom' });
  register({ name: 'subStatus', type: 'custom' });
  register({ name: 'dataType', type: 'custom' });
  register({ name: 'leaseProvider', type: 'custom' });
  register({ name: 'cancelledSubStatus', type: 'custom' });
  register({ name: 'costMin', type: 'custom' });
  register({ name: 'costMax', type: 'custom' });

  const {
    paymentStatus,
    subStatus,
    dataType,
    leaseProvider,
    cancelledSubStatus,
    costMin,
    costMax
  } = watch();

  useEffect(() => {
    if (paymentStatus === 'Cancelled') {
      setShowCancelledSubStatus(true);
    } else {
      setShowCancelledSubStatus(false);
    }

  }, [paymentStatus]);

  useEffect(() => {
    if (leaseProvidersList?.length) {
      const requestBodyNew = {};
      // eslint-disable-next-line array-callback-return
      LEASE_PROVIDERS_TYPE.map(lease_provider => {
        const provider_with_risk_profile_data = leaseProvidersList.find(item => item.name === lease_provider.value);

        if (provider_with_risk_profile_data?.risk_profile_uid) {
          requestBodyNew[lease_provider.value] = provider_with_risk_profile_data.risk_profile_uid;
        }
      });

      setRequestBody({ ...requestBody, ...requestBodyNew });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseProvidersList]);

  const handleChange = event => {
    event.persist();
    const new_body = { ...requestBody };
    new_body[`${event.target.name}`] = event.target.value;
    console.log(new_body);
    setRequestBody(new_body);
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ paymentStatus, subStatus, fromDate, toDate, dataType, leaseProvider, cancelledSubStatus, costMin, costMax }) => {

          const payload = { requestBody: requestBody };
          // console.log('!!!!!!!!!!!!!!!!!!!!!!!');
          // console.log(payload);
          dispatch({
            type: 'SET_ORDERS_TEST_VALUES',
            payload
          });

          setStatus(paymentStatus);
          setSubStatus(subStatus);
          setDataType(dataType);
          setToDate(toDate);
          setFromDate(fromDate);
          setLeaseProvider(leaseProvider);
          setCancelledSubStatus(cancelledSubStatus);
          setCostMin(costMin);
          setCostMax(costMax);
          dataReloadInit();
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />

          {LEASE_PROVIDERS_TYPE.map(lease_provider =>
            <div className={classes.contentSectionContent} key={lease_provider.value}>
              <div className={classes.formGroup}>
                {/* eslint-disable-next-line no-mixed-operators */}
                {riskProfileList.length > 0 && <TextField
                  className={classes.field}
                  fullWidth
                  label={'Risk profile for ' + lease_provider.label}
                  name={lease_provider.value}
                  value={requestBody[lease_provider.value]}
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  <option value="" />
                  {riskProfileList.map((riskProfile, id) => (
                    <option
                      key={id}
                      value={riskProfile.uid}
                    >
                      {riskProfile.name}
                    </option>
                  ))}
                  {/* eslint-disable-next-line no-mixed-operators */}
                </TextField> || <React.Fragment>
                  <Typography display="" variant="h6">List of risk profile is empty.</Typography>
                  <Typography display="" variant="h6">Please add new risk profile for setting to lease provider.</Typography>
                </React.Fragment>}
              </div>
            </div>
          )}
        </div>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          <div className={classes.contentSectionContent}>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="Status"
                name="paymentStatus"
                onChange={({ currentTarget }) => setValue('paymentStatus', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={paymentStatus}
                variant="outlined"
              >
                <option value="" />
                {PAYMENT_STATUS_OPTIONS.map(option => (
                  <option
                    key={option}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </TextField>
            </div>

            {(!showCancelledSubStatus) ||
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="Cancelled SubStatus"
                name="cancelledSubStatus"
                onChange={({ currentTarget }) => setValue('cancelledSubStatus', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={cancelledSubStatus}
                variant="outlined"
              >
                <option value="" />
                {cancelledSubStatuses.map(option => (
                  <option
                    key={option}
                    value={option}
                  >
                    {cancelledSubStatusMasquerade[option]}
                  </option>
                ))}
              </TextField>
            </div>
            }

            {/*{paymentStatus==='Manual Verification Required' && */}
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="SubStatus"
                name="subStatus"
                onChange={({ currentTarget }) => setValue('subStatus', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={subStatus}
                variant="outlined"
              >
                <option value="" />
                {paymentSubStatusOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            {/*}*/}
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="Additional Options"
                name="dataType"
                onChange={({ currentTarget }) => setValue('dataType', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={dataType}
                variant="outlined"
              >
                <option value="" />
                {paymentDataTypeOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="Lease Provider"
                name="leaseProvider"
                onChange={({ currentTarget }) => setValue('leaseProvider', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={leaseProvider}
                variant="outlined"
              >
                <option value="" />
                {LEASE_PROVIDERS_TYPE.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            {/*<div className={classes.contentSectionContent}>*/}
            <div className={classes.formGroup}>
              <DatePickerField
                fieldName={'fromDate'}
                label={'From Date'}
                maxDateFieldName={'toDate'}
              />
            </div>
            <div className={classes.formGroup}>
              <DatePickerField
                fieldName={'toDate'}
                label={'To Date'}
                minDateFieldName={'fromDate'}
              />
            </div>

            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                inputProps={{
                  // className: classes.field_2,
                  step: 0.01,
                  min: 0.0
                }}
                label="Order price more than"
                name="costMin"
                onChange={({ currentTarget }) => setValue('costMin', currentTarget.value, true)}
                // required
                type="number"
                value={costMin}
                variant="outlined"
              />
            </div>

            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                id="cost_min"
                inputProps={{
                  // className: classes.field_2,
                  step: 0.01,
                  min: 0.0
                }}
                label="Order price less than"
                name="costMax"
                onChange={({ currentTarget }) => setValue('costMax', currentTarget.value, true)}
                // required
                type="number"
                value={costMax}
                variant="outlined"
              />
            </div>
            {/*</div>*/}
          </div>
          {/*<div>*/}
          {/*  {JSON.stringify(watch())}*/}
          {/*</div>*/}
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              disabled={Object.keys(requestBody).length !== LEASE_PROVIDERS_TYPE.length}
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  dataReloadInit: PropTypes.func.isRequired
};
