import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography, Button, colors
} from '@material-ui/core';
import moment from 'moment';
import { LEASE_PROVIDERS_TYPE } from 'common';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import PopupSyncLease from '../PopupSyncLease/PopupSyncLease';
import {syncLeaseProvider, getAndUpdateFullStatusUownProvider} from '../../../../utils/resources';
import { isEmpty, startCase } from 'lodash';
import {Label} from '../../../../components';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
  columnTable: {
    width: '30%'
  },
  address: {
    textTransform: 'capitalize',
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4,
    color: colors.grey[100]
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  getFullStatusBtn: {
    margin: theme.spacing(1),
    float: 'right',
  },
  fullDataSubValue: {
    display: 'flex',
    borderBottom: '1px solid #546e7a',
    '&:last-child': {
      borderBottom: 'none',
    },
    '& > p': { marginRight: 24 }
  },
}));

export const LeaseProviderInfo = props => {

  const { customer, className, lease_provider, dataReloadInit, /* isEditMode,*/ ...rest } = props;

  const classes = useStyles();

  const [leaseProviderFields, setLeaseProviderFields] = useState([]);
  const [labelLeaseProvider, setLabelLeaseProvider] = useState(lease_provider);
  const [showComponent, setShowComponent] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [isGettingFullStatus, setIsGettingFullStatus] = useState(false);
  const [isOpenPopupSyncLease, setIsOpenPopupSyncLease] = useState(false);
  const [fullStatusOfLease, setFullStatusOfLease] = useState({});
  // const [isExistAddress, setIsExistAddress] = useState(false);

  useEffect(  () => {
    const itemLeaseProvider = LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider);
    if (itemLeaseProvider) {
      setLabelLeaseProvider(itemLeaseProvider.label);
      setLeaseProviderFields(itemLeaseProvider.fields || '');
    }
    // LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.label || DEFAULT_LEASE_PROVIDER.label;
  }, [setLabelLeaseProvider, setLeaseProviderFields, lease_provider]);

  useEffect(  () => {
    let isExistAddress = false;
    if (customer[lease_provider]?.address?.first_name ||
      customer[lease_provider]?.address?.last_name ||
      customer[lease_provider]?.address?.street_1 ||
      customer[lease_provider]?.address?.street_2 ||
      customer[lease_provider]?.address?.city ||
      customer[lease_provider]?.address?.phone ||
      customer[lease_provider]?.address?.email
    ) {
      isExistAddress = true;
    }

    if (customer[lease_provider]?.application_status ||
      customer[lease_provider]?.created ||
      customer[lease_provider]?.approval_amount ||
      customer[lease_provider]?.application_id ||
      customer[lease_provider]?.transaction_id ||
      customer[lease_provider]?.checkout_token ||
      customer[lease_provider]?.lease_id ||
      customer[lease_provider]?.lease_number ||
      customer[lease_provider]?.application_token ||
      isExistAddress
    ) {
      setShowComponent(true);
    }
  }, [setShowComponent, customer, lease_provider]);

  // useEffect(  () => {
  //   if (customer[lease_provider]?.address?.first_name ||
  //     customer[lease_provider]?.address?.last_name ||
  //     customer[lease_provider]?.address?.street_1 ||
  //     customer[lease_provider]?.address?.street_2 ||
  //     customer[lease_provider]?.address?.city ||
  //     customer[lease_provider]?.address?.phone ||
  //     customer[lease_provider]?.address?.email
  //   ) {
  //     setIsExistAddress(true);
  //   }
  // }, [setIsExistAddress, customer, lease_provider]);


  if (!customer[lease_provider]) return;

  const closePopupSyncLease = () => {
    setIsOpenPopupSyncLease(false);
    setIsSync(false);
  }

  const syncLease = async () => {
    try {
      setIsSync(true);
      await syncLeaseProvider({customer_uid: customer._id, provider: lease_provider});
      dataReloadInit();
      setIsSync(false);
    } catch (err) {
      setIsSync(false);
    }
  }

  const getAndUpdateFullStatusUown = async () => {
    try {
      setIsGettingFullStatus(true);
      const res = await getAndUpdateFullStatusUownProvider({customer_uid: customer._id, provider: lease_provider});
      res?.data?.success && setFullStatusOfLease(res.data.data);
      setIsGettingFullStatus(false);
    } catch (err) {
      setIsGettingFullStatus(false);
    }
  }

  return (
    <> {showComponent && <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title={labelLeaseProvider} action={ (lease_provider === 'acima' && (customer[lease_provider]['application_id'] || customer[lease_provider]['lease_id'] )) &&
        <Button
          color="primary"
          onClick={()=> {setIsOpenPopupSyncLease(true)}}
          variant="contained"
          className={classes.footerBtn}
          disabled={isSync}
        >
          {(isSync) ?
            <>
              <CircularProgress
                className={classes.spinnerIcon}
                size={20}
              /> Sync
            </> :
            <>
              <RefreshIcon className={classes.filterIcon} /> Sync
            </>
          }
        </Button>
      }
      />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>

            {leaseProviderFields?.includes('application_status') && <TableRow className={classes.tableSelected}>
              <TableCell className={classes.columnTable}>
                <Typography variant="subtitle2">
                  Application Status
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer[lease_provider]?.application_status || ''}</div>
              </TableCell>
            </TableRow>}

            {leaseProviderFields.includes('created') && <TableRow /*selected*/ /*className={classes.tableSelected}*/>
              <TableCell>
                <Typography variant="subtitle2">
                  Created
                </Typography>
              </TableCell>
              <TableCell>
                {customer[lease_provider]?.created ? moment(customer[lease_provider]?.created).format('DD MMM YYYY  h:mm a') : ''/*<Typography variant="subtitle2">N/A</Typography>*/}
              </TableCell>
            </TableRow>}

            {leaseProviderFields.includes('approval_amount') && <TableRow /* className={classes.tableSelected}*/>
              <TableCell>
                <Typography variant="subtitle2">
                  Approval Amount
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer[lease_provider]?.approval_amount ? ('$' + customer[lease_provider]?.approval_amount) : ''}</div>
              </TableCell>
            </TableRow>}

            {leaseProviderFields.includes('is_added_amount') && <TableRow /* className={classes.tableSelected}*/>
              <TableCell>
                <Typography variant="subtitle2">
                  Is Added Amount
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer[lease_provider]?.is_added_amount ? 'Yes' : 'No'}</div>
              </TableCell>
            </TableRow>}

            {leaseProviderFields.includes('original_amount') && <TableRow /* className={classes.tableSelected}*/>
              <TableCell>
                <Typography variant="subtitle2">
                  Original Amount
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer[lease_provider]?.original_amount ? ('$' + customer[lease_provider]?.original_amount) : '-'}</div>
              </TableCell>
            </TableRow>}

            {leaseProviderFields.includes('application_id') &&
            <TableRow /*selected*//* className={classes.tableSelected}*/>
              <TableCell>
                <Typography variant="subtitle2">
                  Application&nbsp;ID
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer[lease_provider]?.application_id || ''}</div>
              </TableCell>
            </TableRow>}

            {leaseProviderFields.includes('application_token') &&
              <TableRow /*selected*//* className={classes.tableSelected}*/>
                <TableCell>
                  <Typography variant="subtitle2">
                    Application&nbsp;Token
                  </Typography>
                </TableCell>
                <TableCell>
                  <div>{customer[lease_provider]?.application_token || ''}</div>
                </TableCell>
              </TableRow>}

            {leaseProviderFields.includes('sub_provider') &&
              <TableRow /*selected*//* className={classes.tableSelected}*/>
                <TableCell>
                  <Typography variant="subtitle2">
                    Sub&nbsp;Provider
                  </Typography>
                </TableCell>
                <TableCell>
                  <div>{customer[lease_provider]?.sub_provider || ''}</div>
                </TableCell>
              </TableRow>}

            {leaseProviderFields.includes('transaction_id') &&
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  Transaction&nbsp;ID
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer[lease_provider]?.transaction_id || ''}</div>
              </TableCell>
            </TableRow>}

            {leaseProviderFields.includes('checkout_token') && <TableRow /* className={classes.tableSelected}*/>
              <TableCell>
                <Typography variant="subtitle2">
                  Checkout Token
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer[lease_provider]?.checkout_token || ''}</div>
              </TableCell>
            </TableRow>}

            {leaseProviderFields.includes('lease_id') && <TableRow /*selected*/ /*className={classes.tableSelected}*/>
              <TableCell>
                <Typography variant="subtitle2">
                  Lease&nbsp;ID
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer[lease_provider]?.lease_id || ''}</div>
              </TableCell>
            </TableRow>}

            {leaseProviderFields.includes('lease_number') && <TableRow /*className={classes.tableSelected}*/>
              <TableCell>
                <Typography variant="subtitle2">
                  Lease&nbsp;Number
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer[lease_provider]?.lease_number || ''}</div>
              </TableCell>
            </TableRow>}

            <TableRow /*className={classes.tableSelected}*/>
              <TableCell>
                <Typography variant="subtitle2">
                  Address
                </Typography>
              </TableCell>
              <TableCell>
                <div
                  className={classes.address}>{customer[lease_provider]?.address?.first_name} {customer[lease_provider]?.address?.last_name}</div>
                <div className={classes.address}>{customer[lease_provider]?.address?.street_1}</div>
                {customer[lease_provider]?.address?.street_2 &&
                <div className={classes.address}>{customer[lease_provider]?.address?.street_2}</div>}
                <div
                  className={classes.address}>{customer[lease_provider]?.address?.city} {customer[lease_provider]?.address?.state} {customer[lease_provider]?.address?.zip}</div>
                <div className={classes.address}>{customer[lease_provider]?.address?.country}</div>
                <div>{customer[lease_provider]?.address?.phone}</div>
                <div>{customer[lease_provider]?.address?.email}</div>
              </TableCell>
            </TableRow>
            {(customer[lease_provider]['source']) &&
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">
                    Source
                  </Typography>
                </TableCell>
                <TableCell>
                  {customer[lease_provider]['source']}
                </TableCell>
              </TableRow>
            }
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  Expired
                </Typography>
              </TableCell>
              <TableCell>
                {(customer[lease_provider]['is_expired'])?
                  <Label
                    color={colors.red[600]}
                    variant="outlined"
                  >Yes</Label> :
                  <Label
                    color={colors.green[600]}
                    variant="outlined"
                  >No</Label>}
              </TableCell>
            </TableRow>

            {!isEmpty(fullStatusOfLease) && Object.entries(fullStatusOfLease).map(([key, value]) => {
              if (key === 'paymentDetailsList' || value === null) return null;

              if (key === 'lineItem' && Array.isArray(value)) {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      <Typography variant="subtitle2">{startCase(key)}</Typography>
                    </TableCell>
                    <TableCell>
                      {value.map((item, index) => (
                        <div className={classes.fullDataSubValue} key={index}>
                          <Typography variant="body1">{index + 1}.</Typography>
                          <ul>
                            {Object.entries(item).map(([subKey, subValue]) => {
                              if (subValue === null) return null
                              return (
                                <li key={subKey}>
                                  <Typography variant="body2">
                                    {startCase(subKey)} - {subValue}
                                  </Typography>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      ))}
                    </TableCell>
                  </TableRow>
                );
              }

              return (<TableRow key={key}>
                <TableCell>
                  <Typography variant="subtitle2">
                    {startCase(key)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <div>{value.toString()}</div>
                </TableCell>
              </TableRow>)
            })}
          </TableBody>
        </Table>
        {(lease_provider === 'uown' || lease_provider === 'uown_furniture') &&
          <Button
            color="primary"
            onClick={getAndUpdateFullStatusUown}
            disabled={isGettingFullStatus}
            variant="contained"
            className={classes.getFullStatusBtn}
          >
            {(isGettingFullStatus) ?
              <>
                <CircularProgress
                  className={classes.spinnerIcon}
                  size={20}
                /> Get Full Status
              </> :
              <>
                <RefreshIcon className={classes.filterIcon} /> Get Full Status
              </>
            }
          </Button>}
      </CardContent>
    </Card>
    }
    <PopupSyncLease isOpen = {isOpenPopupSyncLease} closePopupSyncLease = {closePopupSyncLease} syncLease = {syncLease} />
    </>);
};

LeaseProviderInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
  lease_provider: PropTypes.string
};

