
export const arrayStatesUS = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'Armed Forces Africa',
  'Armed Forces Americas',
  'Armed Forces Canada',
  'Armed Forces Europe',
  'Armed Forces Middle East',
  'Armed Forces Pacific',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States Of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Islands',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

/* from GIGA documentation (shipment restriction areas: AK, HI, PR, AA, AP, GU
    PR - Puerto Rico,
    AK - Alaska,
    HI - Hawaii,
    GU - Guam,
    AA - Armed Forces Americas,
    AE - Armed Forces Africa, Armed Forces Canada, Armed Forces Europe, Armed Forces Middle East
    AP - Armed Forces Pacific
 */
export const notUseStateNames =  ['Alaska', 'AK', 'Hawaii', 'HI', 'Puerto Rico', 'PR', 'Armed Forces Americas', 'AA',
  'Armed Forces Africa', 'Armed Forces Canada', 'Armed Forces Europe', 'Armed Forces Middle East',
  'Armed Forces Pacific','AE', 'Guam', 'GU'];

