import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios_v2 from './axios_v2';
import {toastHttpError} from './common';
import moment from "moment/moment";

export const useGetReportLedger = () => {

  const prepareParams = useCallback(({fromDate, toDate, status, type, sortBy, sortDirection, state, leaseProvider, storeType}) => {
    const params = {};
    if (status) { params.status = status;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString());}
    if (type) { params.type = type;}
    if (sortBy) { params.sort_by = sortBy;}
    if (sortDirection) { params.sort_direction = sortDirection;}
    if (state) { params.state = state;}
    if (leaseProvider) { params.lease_provider = leaseProvider;}
    if (storeType) { params.store_type = storeType;}

    return params
  }, []);

  const { fromDate, toDate, status, type, sortBy, sortDirection,
    state, leaseProvider, storeType, setFromDate, setToDate } = useFiltersSearch();

  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  useEffect(function() {
    let isChange= false;
    if (!fromDate) {
      setFromDate(moment());
      isChange = true;
    }
    if (!toDate) {
      setToDate(moment());
      isChange = true;
    }
    if (isChange) dataReloadInit();
  }, [dataReloadInit, fromDate, toDate, setFromDate, setToDate]);

  const handleResponse = useCallback((ordersPromise) => {
    ordersPromise.then(response => {

      if (response?.data?.count) {
        setOrdersCount(response.data.count);

        if (response?.data?.data) {
          setOrders(response.data.data);
        }
      } else {
        setOrdersCount(0);
        setOrders([]);
      }
    }).catch(response => {
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      setOrdersCount(0);
      setOrders([]);
      toastHttpError(response);
    });
  }, []);

  useEffect(function() {

    if (fromDate && toDate) {

      handleResponse(axios_v2().get('/reports/ledger', {
        params: {
          ...prepareParams({ fromDate, toDate, status, type, sortBy, sortDirection, state, leaseProvider, storeType })
        }
      }));

    } else {
      setOrdersCount(0);
      setOrders([]);
    }
  }, [fromDate, toDate, status, type, state, sortBy, sortDirection, leaseProvider, storeType, handleResponse, prepareParams, setOrdersCount, setOrders, dataReload]);

  return {
    orders,
    ordersCount,
    dataReloadInit
  };
};