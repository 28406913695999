import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Checkbox, Table, colors
} from '@material-ui/core';
import axios_v2 from 'utils/axios_v2';
import { withRouter } from 'react-router-dom';
import notifyError, { toastHttpError } from 'utils/common.js'
import { toast } from 'react-toastify';
import validate from 'validate.js';
import { LEASE_PROVIDERS_TYPE } from 'common';
import _isEmpty from 'lodash/isEmpty';
// import { camelCase } from 'lodash'

const leaseProviderType = LEASE_PROVIDERS_TYPE.filter(provider => provider.store_type === 'furniture');

const schemaCVV = {
  cvvCode: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { maximum: 16 }
  },
  discountMultiplierPercent: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  orderPriceLess: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: { greaterThanOrEqualTo: 0 }
  },
  customerAgeMax: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: { greaterThanOrEqualTo: 0 }
  },
  ...leaseProviderType.reduce((schema, provider) => {
    schema[`${provider.value}Percent`] = {
      presence: { allowEmpty: false, message: 'is required' },
      numericality: {
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 100
      }
    };
    schema[`percentTraffic_${provider.value}`] = {
      presence: true,
      numericality: {
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 100
      }
    };
    return schema;
  }, {})
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    },
  },
  warningRed: {
    fontWeight: 'bold',
    color: colors.red[600],
  },
  container1: {
    width: '100%'
  }
}));

const FurniturePaymentAccountDetails = props => {

  const {className } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      cvvCode: '',
      discountMultiplierPercent: 0.0,
      orderPriceLess: 1900,
      customerAgeMax: 60,
      ...leaseProviderType.reduce((acc, provider) => {
        acc[`${provider.value}Percent`] = 0.0;
        acc[`percentTraffic_${provider.value}`] = 0.0;
        return acc;
      }, {}),
    },
    touched: {},
    errors: {}
  });

  const [orderAutoplacingForProviderList, setOrderAutoplacingForProviderList] = useState([]);

  const handleAutoPlacingChange = event => {
    event.persist();

    let newOrderAutoplacingForProviderList = [...orderAutoplacingForProviderList];

    if (event.target.checked) {
      newOrderAutoplacingForProviderList.push(event.target.name);
      newOrderAutoplacingForProviderList = [...new Set(newOrderAutoplacingForProviderList)];
    } else {
      const index = newOrderAutoplacingForProviderList.indexOf(event.target.name);
      if (index > -1) {
        newOrderAutoplacingForProviderList.splice(index, 1);
      }
    }
    setOrderAutoplacingForProviderList(newOrderAutoplacingForProviderList);
  };

  const [enabledProviderList, setEnabledProviderList] = useState([]);

  const handleEnabledProviderListChange = event => {
    event.persist();

    // const capitalize = (str) => str.trim().charAt(0).toUpperCase() + camelCase(str).slice(1)
    let newEnabledProviderList = [...enabledProviderList];

    if (event.target.checked) {
      newEnabledProviderList.push(event.target.name);
      newEnabledProviderList = [...new Set(newEnabledProviderList)];
    } else {
      const index = newEnabledProviderList.indexOf(event.target.name);
      if (index > -1) {
        // --> set percentTraffic_<provider>: 0.0
        const values = {
          ...formState.values,
          [`percentTraffic_${event.target.name}`]: 0.0
        };

        const errors = validate( values, schemaCVV) || {};

        setFormState(formState => ({
          ...formState,
          values,
          touched: {
            ...formState.touched,
            [`percentTraffic_${event.target.name}`]: true
          },
          isValid: _isEmpty(errors),
          errors: errors
        }));
        // <--

        newEnabledProviderList.splice(index, 1);
      }
    }
    setEnabledProviderList(newEnabledProviderList);
  };

  const fetchStorySettings = () => {
    return  axios_v2().get('/settings?type=furniture').then(response => {
      const newValues = { /*...formState.values*/ };
      if(response?.data?.commerce_acc_cvv) {
        newValues['cvvCode'] = response?.data?.commerce_acc_cvv;
      } else {
        notifyError('CVV not found');
      }

      if (response?.data?.discount_multiplier_percent === undefined || response?.data?.discount_multiplier_percent === null) {
        notifyError('Discount multiplier percent not found');
      } else {
        newValues['discountMultiplierPercent'] = response.data.discount_multiplier_percent;
      }

      if (response?.data?.order_autoplacing_for_provider_list) {
        setOrderAutoplacingForProviderList(response.data.order_autoplacing_for_provider_list);
      }

      if (response?.data?.enabled_provider_list) {
        setEnabledProviderList(response.data.enabled_provider_list);
      }

      if (response?.data?.order_price_less === undefined || response?.data?.order_price_less === null) {
        notifyError('order_price_less not found');
      } else {
        newValues['orderPriceLess'] = response.data.order_price_less;
      }

      if (response?.data?.customer_age_max === undefined || response?.data?.customer_age_max === null) {
        notifyError('customer_age_max not found');
      } else {
        newValues['customerAgeMax'] = response.data.customer_age_max;
      }

      leaseProviderType.forEach(provider => {
        const key = `${provider.value}_percent`;
        if (response?.data?.[key] !== undefined && response?.data?.[key] !== null) {
          newValues[`${provider.value}Percent`] = response.data[key];
        } else {
          notifyError(`${provider.label} percent not found`);
        }

        const percentTrafficKey = `percent_traffic_${provider.value}`;
        if (response?.data?.[percentTrafficKey] !== undefined && response?.data?.[percentTrafficKey] !== null) {
          newValues[`percentTraffic_${provider.value}`] = response.data[percentTrafficKey];
        } else {
          notifyError(`${provider.label} percent traffic not found`);
        }
      });

      return newValues;
    }).catch(response => {
      toastHttpError(response);
      //history.push('/')
    });
  };

  useEffect(()=> {
    (async () => {

      const fetchedSettings = await fetchStorySettings();

      const {
        cvvCode,
        discountMultiplierPercent,
        orderPriceLess,
        customerAgeMax
      } = fetchedSettings;

      const leaseTrafficValues = leaseProviderType.reduce((acc, provider) => {
        acc[`${provider.value}Percent`] = fetchedSettings[`${provider.value}Percent`];
        acc[`percentTraffic_${provider.value}`] = fetchedSettings[`percentTraffic_${provider.value}`];
        return acc;
      }, {});

      const values = {
        cvvCode,
        discountMultiplierPercent,
        orderPriceLess,
        customerAgeMax,
        ...leaseTrafficValues
      };

      const errors = validate( values, schemaCVV);
      console.log({fetchedSettings, values, errors}) // DEBUG
      setFormState(formState => ({
        ...formState,
        values,
        isValid: _isEmpty(errors),
        errors: errors || {}
      }));
    })().catch(error => {
      console.log(error);
    });
  }, []);  //formState.values

  const handleChange = event => {
    event.persist();

    const values = {
      ...formState.values,
      [event.target.name]: event.target.value
    };

    const errors = validate( values, schemaCVV) || {};

    setFormState(formState => ({
      ...formState,
      values,
      touched: {
        ...formState.touched,
        [event.target.name]: true
      },
      isValid: _isEmpty(errors),
      errors: errors
    }));

  };

  const totalPercentTraffic = () => {
    if (!enabledProviderList?.length) return true;

    const total = leaseProviderType.reduce((sum, provider) => {
      const key = `percentTraffic_${provider.value}`;
      sum = sum + (formState.values[key] ? (+formState.values[key] || 0) : 0);
      return parseFloat(sum.toFixed(2));
    }, 0);
    return total === 100;
  }

  const handleButtonSaveDetails = useCallback(() => {
    if (!totalPercentTraffic()) {
      const errorText = 'The amount of Traffic Allocation should be equal to 100%';
      toast.error(errorText);
    } else {
      const data = {
        'type': 'furniture',
        'commerce_acc_cvv': formState.values.cvvCode || '',
        'discount_multiplier_percent': formState.values.discountMultiplierPercent === undefined || formState.values.discountMultiplierPercent === null ? '0' : formState.values.discountMultiplierPercent.toString(),
        'order_autoplacing_for_provider_list': orderAutoplacingForProviderList,
        'enabled_provider_list': enabledProviderList,
        'order_price_less': formState.values.orderPriceLess === undefined || formState.values.orderPriceLess === null ? '1900' : formState.values.orderPriceLess.toString(),
        'customer_age_max': formState.values.customerAgeMax === undefined || formState.values.customerAgeMax === null ? '60' : formState.values.customerAgeMax.toString(),
        ...leaseProviderType.reduce((acc, provider) => {
          acc[`${provider.value}_percent`] = formState.values[`${provider.value}Percent`];
          acc[`percent_traffic_${provider.value}`] = formState.values[`percentTraffic_${provider.value}`];
          return acc;
        }, {})
      }

      axios_v2().put('/settings', data).then(() => {
        const successText = 'Saved Successfully';
        toast.success(successText);
      }).catch(response => {
        toastHttpError(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, orderAutoplacingForProviderList, enabledProviderList]);

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="The information can be edited"
          title="Furniture Store Payment Account Settings"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('cvvCode')}
                fullWidth
                helperText={
                  hasError('cvvCode') ? formState.errors.cvvCode[0] : null
                }
                label="CVV code"
                margin="dense"
                name="cvvCode"
                onChange={handleChange}
                required
                type="text"
                value={formState.values.cvvCode}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('discountMultiplierPercent')}
                fullWidth
                helperText={
                  hasError('discountMultiplierPercent') ? formState.errors.discountMultiplierPercent[0] : null
                }
                inputProps={{
                  step: 0.1,
                  min: 0.0,
                  max: 100.0
                }}
                label="Discount multiplier percent"
                margin="dense"
                name="discountMultiplierPercent"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.discountMultiplierPercent}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('customerAgeMax')}
                fullWidth
                helperText={
                  hasError('customerAgeMax') ? formState.errors.customerAgeMax[0] : null
                }
                label="Max age customer"
                margin="dense"
                name="customerAgeMax"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.customerAgeMax}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Auto placing the order on GIGA</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {leaseProviderType.map((lease_provider/*, index*/) => (
                      <TableRow
                        hover
                        key={lease_provider.value + '_auto_playcing'}
                      >
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={orderAutoplacingForProviderList.includes(lease_provider.value)}
                                color="primary"
                                name={lease_provider.value}
                                onChange={handleAutoPlacingChange}
                                value={lease_provider.value}
                              />
                            }
                            label={lease_provider.label}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                </TableBody>
              </Table>
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Enabled Lease Providers (Warning! Be sure of what you do!)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {leaseProviderType.map((lease_provider/*, index*/) => (
                      <TableRow
                        hover
                        key={lease_provider.value + '_enabled'}
                      >
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={enabledProviderList.includes(lease_provider.value)}
                                name={lease_provider.value}
                                onChange={handleEnabledProviderListChange}
                                value={lease_provider.value}
                              />
                            }
                            label={<span className={classes.warningRed}>
                              {lease_provider.label} provider is ENABLED
                            </span>}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
        <CardHeader
          title="Report Settings"
        />
        <Divider />
        {leaseProviderType.map((provider, index) => (
          <CardContent key={'percent_' + index}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasError(`${provider.value}Percent`)}
                  fullWidth
                  helperText={
                    hasError(`${provider.value}Percent`) ? formState.errors[[`${provider.value}Percent`]][0] : null
                  }
                  inputProps={{
                    step: 0.1,
                    min: 0.0,
                    max: 100.0
                  }}
                  label={`${provider.label} percent (for reports)`}
                  margin="dense"
                  name={`${provider.value}Percent`}
                  onChange={handleChange}
                  required
                  type="number"
                  value={formState.values[`${provider.value}Percent`]}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>))}
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('orderPriceLess')}
                fullWidth
                helperText={
                  hasError('orderPriceLess') ? formState.errors.orderPriceLess[0] : null
                }
                label="Order price less for XLS reports ($)"
                margin="dense"
                name="orderPriceLess"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.orderPriceLess}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardHeader
          title="Traffic Allocation"
        />
        <Divider />
        {leaseProviderType.map((provider, index) => (
          <CardContent key={'percentTraffic_' + index}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  disabled={!enabledProviderList.includes(provider.value)}
                  error={hasError(`percentTraffic_${provider.value}`)}
                  fullWidth
                  helperText={
                    hasError(`percentTraffic_${provider.value}`) ? formState.errors[`percentTraffic_${provider.value}`][0] : null
                  }
                  inputProps={{
                    step: 1.0,
                    min: 0.0
                  }}
                  label={`${provider.label} %`}
                  margin="dense"
                  name={`percentTraffic_${provider.value}`}
                  onChange={handleChange}
                  required
                  type="number"
                  value={formState.values[`percentTraffic_${provider.value}`]}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>))}
        <CardActions>
          <Button
            className={classes.saveDetailsButton}
            color="primary"
            disabled={!formState.isValid}
            onClick={handleButtonSaveDetails}
            variant="contained"
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

FurniturePaymentAccountDetails.propTypes = {
  className: PropTypes.string
};

export default withRouter(FurniturePaymentAccountDetails);
