import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Results } from './components';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch, useGetReportLedger } from 'utils';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    marginTop: 10
  }
}));

const OrderLedger = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fromDate, toDate, status, type, sortBy, sortDirection, leaseProvider, storeType } = useFiltersSearch();
  const {ordersCount, orders, dataReloadInit} = useGetReportLedger();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      paymentStatus: status || '',
      fromDate: fromDate || null,
      toDate: toDate || null,
      orderType: type || '',
      sortBy: sortBy || 'date',
      sortDirection: sortDirection || 'desc',
      leaseProvider: leaseProvider || '',
      storeType: storeType || ''
    }
  });

  useEffect(() => {
    const payload = {
      main_title: 'Reports',
      secondary_title: 'Ledger',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Orders Ledger Report"
      >
        <Results
          className={classes.results}
          dataReloadInit={dataReloadInit}
          orders={orders}
          ordersCount={ordersCount}
        />
      </Page>
    </FormContext>
  );
};

export default OrderLedger;
