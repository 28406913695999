import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios_v2 from './axios_v2';
import {toastHttpError} from './common';
import moment from 'moment';

export const useGetReportLedgerSummary = () => {
  const { fromDate, toDate, status, type, state, leaseProvider, storeType, setFromDate, setToDate } = useFiltersSearch();
  const [ledgerList, setLedgerList] = useState([]);
  const [ledgerListCount, setLedgerListCount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  useEffect(function() {
    let isChange= false;
    if (!fromDate) {
      setFromDate(moment());
      isChange = true;
    }
    if (!toDate) {
      setToDate(moment());
      isChange = true;
    }
    if (isChange) dataReloadInit();
  }, [dataReloadInit, fromDate, toDate, setFromDate, setToDate]);

  const prepareParams = useCallback(({fromDate, toDate, status, type, state, leaseProvider, storeType}) => {
    const params = {};
    if (status) { params.status = status;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString());}
    if (type) { params.type = type;}
    if (state) { params.state = state;}
    if (leaseProvider) { params.lease_provider = leaseProvider;}
    if (storeType) { params.store_type = storeType;}

    return params;
  }, []);

  const handleResponse = useCallback((ledgerListPromise) => {
    setLedgerListCount(0);
    setLedgerList([]);
    setInProgress(true);

    ledgerListPromise.then(response => {

      if (response?.data?.count) {
        setLedgerListCount(response.data.count);

        if (response?.data?.data) {
          setLedgerList(response.data.data);
        }
      } else {
        setLedgerListCount(0);
        setLedgerList([]);
      }

      setInProgress(false);
    }).catch(response => {
      setInProgress(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      setLedgerListCount(0);
      setLedgerList([]);
      toastHttpError(response);
    });
  }, []);

  useEffect(function() {

    if (fromDate && toDate) {

      handleResponse(axios_v2().get('/reports/ledger_summary', {
        params: {
          ...prepareParams({ fromDate, toDate, status, type, state, leaseProvider, storeType })
        }
      }));

    } else {
      setLedgerListCount(0);
      setLedgerList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*fromDate, toDate, status, type, state, leaseProvider, storeType*/ handleResponse, prepareParams, setLedgerListCount, setLedgerList, dataReload]);

  return {
    ledgerList,
    ledgerListCount,
    inProgress,
    dataReloadInit
  };
};
