import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link, Button, CardActions, CardHeader, Divider
} from '@material-ui/core';

import {
  FILTER_FROM_PARAM_NAME,
  FILTER_LEASE_PROVIDER,
  FILTER_STATE_PARAM_NAME,
  FILTER_STATUS_PARAM_NAME,
  FILTER_STORE_TYPE_PARAM_NAME,
  FILTER_TO_PARAM_NAME,
  FILTER_TYPE_PARAM_NAME,
  useFiltersSearch
} from 'utils';

import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import { Link as RouterLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import {
  Chart,
  Series,
  ValueAxis,
  Legend,
  Label,
  Format,
  Tooltip,
} from 'devextreme-react/chart';
import { orderTypesOptions } from 'components/LedgerSummaryFilter/components/Filter/Filter'
import { useFormContext } from 'react-hook-form';
import ClearIcon from '@material-ui/icons/Clear';
import { LedgerSummaryFilter } from 'components';
import { DEFAULT_LEASE_PROVIDER, LEASE_PROVIDERS_TYPE } from 'common/Constants';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  footerTotal: {
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  footerTotalRow: {
    backgroundColor: '#fafafa'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableWrapper: {
    height: 'calc(100vh - 330px)',
    overflow: 'auto',
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  resultChart: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  pointTooltip: {
    textAlign: 'right'
  },
  tooltipPrice: {
    fontWeight: 'bold'
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  headActions: {
    marginTop: 0,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:509px)']: {
      marginTop: theme.spacing(2),
      // marginLeft: 0
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  title: {
    marginRight: '30px',
  }
}));

const Results = props => {
  const { className, ledgerListCount, ledgerList, inprogress, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { fromDate, toDate, status, type, state, leaseProvider, storeType,
    setFromDate, setToDate, setStatus, setType, setState, setLeaseProvider, setStoreType } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, status, type, state, leaseProvider, storeType]);

  const { setValue } = useFormContext();

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearStatus = useCallback(() => {
    setStatus('');
    setValue('paymentStatus', '', true);
    dataReloadInit();
  }, [dataReloadInit, setStatus, setValue]);

  const clearType = useCallback(() => {
    setType('');
    setValue('orderType', '', true);
    dataReloadInit();
  }, [dataReloadInit, setType, setValue]);

  const clearState = useCallback(() => {
    setState('');
    setValue('state', '', true);
    dataReloadInit();
  }, [dataReloadInit, setState, setValue]);

  const clearLeaseProvider = useCallback(() => {
    setLeaseProvider('');
    setValue('leaseProvider', '', true);
    dataReloadInit();
  }, [dataReloadInit, setLeaseProvider, setValue]);

  const clearStoreType = useCallback(() => {
    setStoreType('');
    setValue('storeType', '', true);
    dataReloadInit();
  }, [dataReloadInit, setStoreType, setValue]);

  const formLink = useCallback( date_value => {
    return '/reports/ledger?' + FILTER_FROM_PARAM_NAME + '=' + encodeURIComponent(moment(date_value).startOf('day').toISOString()) +
      '&' + FILTER_TO_PARAM_NAME + '=' + encodeURIComponent(moment(date_value).endOf('day').toISOString()) +
      ( leaseProvider ? ('&' + FILTER_LEASE_PROVIDER + '=' + leaseProvider) : '') +
      ( storeType ? ('&' + FILTER_STORE_TYPE_PARAM_NAME + '=' + storeType) : '') +
      ( status ? ('&' + FILTER_STATUS_PARAM_NAME + '=' + status) : '') +
      ( type ? ('&' + FILTER_TYPE_PARAM_NAME + '=' + type) : '') +
      ( state ? ('&' + FILTER_STATE_PARAM_NAME + '=' + state) : '');
  }, [leaseProvider, status, type, state, storeType]);

  const getOrderType = () => {
    const elem = orderTypesOptions.find((element) => element.value === type);
    const textType = elem?.label || type;
    return ' Order type: [' + textType + ']'
  }

  const getNameLeaseProviderInfo = useCallback((lease_provider) => {
    return LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.label || DEFAULT_LEASE_PROVIDER.label;
  }, []);

  function TooltipTemplate(info) {
    return (
      <div className={classes.pointTooltip}>
        <div className={classes.tooltipPrice}>
          $ {info.value.toFixed(2)}
        </div>

        <div>
          {info.argumentText}
        </div>

      </div>
    );
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, classes.card, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {fromDate && <Button
          onClick={clearFromDate}
          name = {'btn-from-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
        </Button>}
        {toDate && <Button
          onClick={clearToDate}
          name = {'btn-to-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
        </Button>}
        {status && <Button
          onClick={clearStatus}
          name = {'btn-status'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Payment status: [${status}]`}
        </Button>}
        {type && <Button
          onClick={clearType }
          name = {'btn-type'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {getOrderType()}
        </Button>}
        {state && <Button
          onClick={clearState}
          name = {'btn-state'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` State: [${state}]`}
        </Button>}
        {leaseProvider && <Button
          onClick={clearLeaseProvider}
          name = {'btn-lease-provider'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Lease Provider: [${ getNameLeaseProviderInfo(leaseProvider) }]`}
        </Button>}
        {storeType && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name = {'btn-store-type'}
          onClick={clearStoreType}
          size="small"
          variant="contained"> {` Store Type: [${ storeType }]`}
        </Button>}
      </div>

      { ledgerList.length>0 && !inprogress && <Paper className={classes.resultChart}>
        <Chart
          dataSource={ledgerList}
        >
          <ValueAxis>
            <Label>
              <Format
                precision={0}
                type="currency"
              />
            </Label>
          </ValueAxis>

          <Series
            name="Revenue"
            valueField="revenue"
            argumentField="date"
          />
          <Series
            name="Discount"
            valueField="discount"
            argumentField="date"
          />
          <Series
            name="Cost of Goods"
            valueField="cost_of_goods"
            argumentField="date"
          />
          <Series
            name="Cancels/Returns"
            valueField="cost_cancels"
            argumentField="date"
          />
          <Series
            name="Lease Provider(s) Charge"
            valueField="lease_provider_charge"
            argumentField="date"
          />
          <Series
            name="BOT Tax Collected"
            valueField="bot_tax"
            argumentField="date"
          />
          {/*<Series*/}
          {/*  name="BestBuy Tax Collected"*/}
          {/*  valueField="bb_tax"*/}
          {/*  argumentField="date"*/}
          {/*/>*/}
          <Series
            name="Net Revenue"
            valueField="net_revenue"
            argumentField="date"
          />
          {!leaseProvider && <Series
            argumentField="date"
            name="Marketing Cost"
            valueField="marketing_cost"
          />}

          <Legend />
          <Tooltip
            enabled={true}
            contentRender={TooltipTemplate}
          />
        </Chart>
      </Paper> }
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          classes={{
            title: classes.title
          }}
          ref = { cardHeaderRef }
          title="Ledger Summary"
          action={
            <LedgerSummaryFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
            />
          }/>
        <Divider/>
      { inprogress && <div className={classes.spinnerRoot}>
        <CircularProgress size={60} />
      </div> }
      { ledgerList.length>0 && !inprogress &&
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          {/*<PerfectScrollbar>*/}
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Date
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Revenue
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Discount
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Cost&nbsp;of&nbsp;Goods
                  </TableCell>
                  {!leaseProvider && <TableCell
                    className={classes.tableCell}
                    align="right"
                  >
                    Marketing&nbsp;Cost
                  </TableCell>}
                  <TableCell
                    className={classes.tableCell}
                    align="right"
                  >
                    Cancels/Returns
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="right"
                  >
                    Lease&nbsp;Provider(s)&nbsp;Charge
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="right"
                  >
                    BOT&nbsp;Tax&nbsp;Collected
                  </TableCell>
                  {/*<TableCell*/}
                  {/*  className={classes.tableCell}*/}
                  {/*  align="right"*/}
                  {/*>*/}
                  {/*  BestBuy&nbsp;Tax&nbsp;Collected*/}
                  {/*</TableCell>*/}
                  <TableCell
                    className={classes.tableCell}
                    align="right"
                  >
                    Net&nbsp;Revenue
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ledgerList?.map(ledger => (
                  <TableRow
                    key={ledger.date}
                    // selected={selectedOrders.indexOf(order.bc_id) !== -1}
                  >
                    <TableCell className={classes.mallIcon}>
                      <Link
                        component={RouterLink}
                        to={formLink(ledger.date)}
                      >
                        <LocalMallOutlinedIcon />
                      </Link>
                    </TableCell>
                    <TableCell
                      className={classes.whiteSpace}
                    >
                      <Link
                        component={RouterLink}
                        to={formLink(ledger.date)}
                      >
                        {moment(ledger.date).format(
                          'DD MMM YYYY'
                        )}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.revenue.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.discount.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.cost_of_goods.toFixed(2)}
                    </TableCell>
                    {!leaseProvider && <TableCell
                      align="right"
                    >
                      ${ledger.marketing_cost.toFixed(2)}
                    </TableCell>}
                    <TableCell
                      align="right"
                    >
                      ${ledger.cost_cancels.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.lease_provider_charge.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.bot_tax.toFixed(2)}
                    </TableCell>
                    {/*<TableCell*/}
                    {/*  align="right"*/}
                    {/*>*/}
                    {/*  ${ledger.bb_tax.toFixed(2)}*/}
                    {/*</TableCell>*/}
                    <TableCell
                      align="right"
                    >
                      ${ledger.net_revenue.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={classes.footerTotalRow}>
                  <TableCell />
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.revenue).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.discount).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.cost_of_goods).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  {!leaseProvider && <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.marketing_cost).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>}
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.cost_cancels).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.lease_provider_charge).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.bot_tax).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  {/*<TableCell*/}
                  {/*  align="right"*/}
                  {/*  className={classes.footerTotal}*/}
                  {/*>*/}
                  {/*  ${ledgerList?.map(ledger => ledger.bb_tax).reduce((a, b) => a + b, 0.0).toFixed(2)}*/}
                  {/*</TableCell>*/}
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.net_revenue).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          {/*</PerfectScrollbar>*/}
        </CardContent>
      }
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {ledgerListCount} Dates with orders found
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  ledgerList: PropTypes.array.isRequired,
  ledgerListCount: PropTypes.number.isRequired,
  inprogress: PropTypes.bool.isRequired
};

Results.defaultProps = {
  ledgerList: [],
  ledgerListCount: 0,
  inprogress: false
};

export default Results;
