import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import OrderItem from '../OrderItem'
import _isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },

  total: {
    fontWeight: 'bold',
  },
  textRight: {
    textAlign: 'right'
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  }
}));

const costTotal = products_data => {
  return products_data?.reduce( (sum, item) => {
    return sum + (item.base_price_custom || parseFloat(item.base_cost_price) || 0.0) * (item.quantity || 1);
  }, 0.0);
};

const OrderItems = props => {
  const { order, edit_mode, className, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const isWithBC = !order.is_without_bc;
  const isBestBuy = _isEmpty(order?.supplier) || order?.supplier === 'bestbuy';

  let custom_index = 0;

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Order items" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>

          {order.products_data.map((item, index) => {

            if (!item.parent_sku) ++custom_index;

            return <OrderItem
              dataReloadInit={dataReloadInit}
              edit_mode={edit_mode}
              index={!item.parent_sku ? custom_index : null}
              item={item}
              key={item.id + index}
              order={order}
            />

          })}

          <div className={classes.inner}>
            <Table>
              <TableBody>

                {!!order.coupon_discount && <TableRow /*selected*/ className={classes.tableSelected}>
                  <TableCell>
                    <div>Coupon Code</div>
                  </TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell>
                    <div
                      className={classes.textRight}
                      name="total_coupon_code"
                    >-${order.coupon_discount?.toFixed(2) || 0.0}</div>
                  </TableCell>
                </TableRow>}

                {isWithBC && <TableRow>
                  <TableCell>
                    <div>BigCommerce Tax</div>
                  </TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell>
                    <div
                      className={classes.textRight}
                      name="total_bigcommerce_tax"
                    >${order.bc_tax?.toFixed(2) || 0.0}</div>
                  </TableCell>
                </TableRow>}
                {isBestBuy && <TableRow>
                  <TableCell>
                    <div>BestBuy Tax</div>
                  </TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell>
                    <div
                      className={classes.textRight}
                      name="total_bestbuy_tax"
                    >${order.bb_tax?.toFixed(2) || 0.0}</div>
                  </TableCell>
                </TableRow>}
                <TableRow>
                  <TableCell>
                    <div>{`${isBestBuy ? 'BestBuy' : 'Giga'} Shipping Cost`}</div>
                  </TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell>
                    <div
                      className={classes.textRight}
                      name="total_bestbuy_shipping_cost"
                    >${order.bb_shipping_cost?.toFixed(2) || 0.0}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div>Purchase Price</div>
                  </TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell>
                    <div
                      className={classes.textRight}
                      name="total_cost_of_goods"
                    >${costTotal(order.products_data).toFixed(2)}</div>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tableSelected} /*selected*/ >
                  <TableCell>
                    <div className={classes.total}>Grand Total</div>
                  </TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell>
                    <div
                      className={clsx(classes.total, classes.textRight)}
                      name="total_grand_total"
                    >${order.total_inc_tax.toFixed(2)}</div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

OrderItems.propTypes = {
  className: PropTypes.string,
  edit_mode: PropTypes.bool,
  order: PropTypes.object.isRequired,
};

export default OrderItems;
