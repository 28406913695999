import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Link, Button, CardActions, CardHeader
} from '@material-ui/core';

import { Label, LedgerFilter } from 'components';
import { useFiltersSearch } from 'utils';
import { Link as RouterLink } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import ClearIcon from '@material-ui/icons/Clear';
import {PAYMENT_STATUS_COLORS} from 'common';
import { DEFAULT_LEASE_PROVIDER, LEASE_PROVIDERS_TYPE } from 'common/Constants';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  footerTotal: {
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  footerTotalRow: {
    backgroundColor: '#fafafa'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  // card: {
  //   height: 'calc(100vh - 130px)',
  // },
  tableWrapper: {
    height: 'calc(100vh - 205px)',
    overflow: 'auto',
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  headActions: {
    marginTop: 0,
    marginRight: theme.spacing(1),
    marginLeft: 0,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:445px)']: {
      marginTop: theme.spacing(2),
      // marginLeft: 0
    }
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  title: {
    marginRight: '30px',
  }
}));

// const paymentStatusColors = {
//   'Pending': colors.deepPurple[600],
//   'Cancelled': colors.grey[600],
//   'Placed With BestBuy': colors.blue[600],
//   'Ready For Pickup': colors.green[600],
//   'Fulfillment Confirmed': colors.lightBlue[600],
//   'Lease Initiated': colors.indigo[600],
//   'Lease Confirmed': colors.teal[600],
//   'Manual Verification Required': colors.red[600]
// };

const Results = props => {
  const { className, ordersCount, orders, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { sortBy, sortDirection, setSortBy, setSortDirection, fromDate, toDate,
         status, type, state, leaseProvider, storeType,
         setFromDate, setToDate, setStatus, setType, setState, setLeaseProvider, setStoreType } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [sortBy, sortDirection, fromDate, toDate, status, type, state, leaseProvider, storeType]);

  const { setValue } = useFormContext();

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearStatus = useCallback(() => {
    setStatus('');
    setValue('paymentStatus', '', true);
    dataReloadInit();
  }, [dataReloadInit, setStatus, setValue]);

  const clearType = useCallback(() => {
    setType('');
    setValue('orderType', '', true);
    dataReloadInit();
  }, [dataReloadInit, setType, setValue]);

  const clearState = useCallback(() => {
    setState('');
    setValue('state', '', true);
    dataReloadInit();
  }, [dataReloadInit, setState, setValue]);

  const clearLeaseProvider = useCallback(() => {
    setLeaseProvider('');
    setValue('leaseProvider', '', true);
    dataReloadInit();
  }, [dataReloadInit, setLeaseProvider, setValue]);

  const clearStoreType = useCallback(() => {
    setStoreType('');
    setValue('storeType', '', true);
    dataReloadInit();
  }, [dataReloadInit, setStoreType, setValue]);

  const handleRequestSort = (event, property) => {
    const isDesc = sortBy === property && sortDirection === 'desc';
    setSortDirection(isDesc ? 'asc' : 'desc');
    setSortBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const getNameLeaseProviderInfo = useCallback((lease_provider) => {
    return LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.label || DEFAULT_LEASE_PROVIDER.label;
  }, []);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {fromDate && <Button
          onClick={clearFromDate}
          name = {'btn-from-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
        </Button>}
        {toDate && <Button
          onClick={clearToDate}
          name = {'btn-to-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
        </Button>}
        {status && <Button
          onClick={clearStatus}
          name = {'btn-status'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Payment status: [${status}]`}
        </Button>}
        {type && <Button
          onClick={clearType }
          name = {'btn-type'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Order type: [${type}]`}
        </Button>}
        {state && <Button
          onClick={clearState}
          name = {'btn-state'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` State: [${state}]`}
        </Button>}
        {leaseProvider && <Button
          onClick={clearLeaseProvider}
          name = {'btn-lease-provider'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Lease Provider: [${ getNameLeaseProviderInfo(leaseProvider) }]`}
        </Button>}
        {storeType && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name = {'btn-store-type'}
          onClick={clearStoreType}
          size="small"
          variant="contained"> {` Store Type: [${ storeType }]`}
        </Button>}
      </div>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          classes={{
            title: classes.title
          }}
          ref = { cardHeaderRef }
          title="Ledger"
          action={
            <LedgerFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
            />
          }/>
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          {/*<PerfectScrollbar>*/}
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    key="date"
                  >
                    <TableSortLabel
                      active={sortBy === 'date'}
                      direction={sortDirection}
                      onClick={createSortHandler('date')}
                    >
                      Date
                      {sortBy ===  'date' ? (
                        <span className={classes.visuallyHidden}>
                          {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    DoradoDigital&nbsp;IDs
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    key="status"
                  >
                    <TableSortLabel
                      active={sortBy === 'status'}
                      direction={sortDirection}
                      onClick={createSortHandler('status')}
                    >
                      Status
                      {sortBy ===  'status' ? (
                        <span className={classes.visuallyHidden}>
                          {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Revenue
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Discount
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Cost&nbsp;of&nbsp;Goods
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Cancels/Returns
                  </TableCell>

                  {/*{leaseProvider && <TableCell*/}
                  {/*  align="right"*/}
                  {/*>*/}
                  {/*  {leaseProvider} Charge*/}
                  {/*</TableCell>}*/}
                  {/*{!leaseProvider && */}
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Lease&nbsp;Provider(s)&nbsp;Charge
                  </TableCell>
                  {/* }*/}
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    BOT&nbsp;Tax&nbsp;Collected
                  </TableCell>
                  {/*<TableCell className={classes.tableCell}*/}
                  {/*  align="right"*/}
                  {/*>*/}
                  {/*  BestBuy&nbsp;Tax&nbsp;Collected*/}
                  {/*</TableCell>*/}
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Net&nbsp;Revenue
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders?.map(order => (
                  <TableRow
                    key={order.bc_id}
                    // selected={selectedOrders.indexOf(order.bc_id) !== -1}
                  >
                    <TableCell className={classes.mallIcon}>
                      <Link
                        component={RouterLink}
                        to={'/orders/' + order.id}
                      >
                        { order.store_type === 'furniture' ? (order.order_source === 'manual' ? <img
                          alt={'Furniture Ico'}
                          src="/images/furniture_m.svg"
                        /> : <img
                          alt={'Furniture Ico'}
                          src="/images/furniture.svg"
                        />) : <img
                          alt={'Electronics Ico'}
                          src="/images/electronics.svg"
                        />}
                      </Link>
                    </TableCell>
                    <TableCell
                      className={classes.whiteSpace}
                    >
                      {moment(order.date_created).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      <Link
                        component={RouterLink}
                        to={'/orders/' + order?.id}
                      >
                        {order.id}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Label
                        color={PAYMENT_STATUS_COLORS[order?.m_status]}
                        variant="outlined"
                      >
                        {order?.m_status}
                      </Label>
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${order?.revenue?.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${order?.discount?.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${order?.cost_of_goods?.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${order?.cost_cancels?.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${order?.lease_provider_charge?.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${order?.bot_tax?.toFixed(2)}
                    </TableCell>
                    {/*<TableCell*/}
                    {/*  align="right"*/}
                    {/*>*/}
                    {/*  ${order?.bb_tax?.toFixed(2)}*/}
                    {/*</TableCell>*/}
                    <TableCell
                      align="right"
                    >
                      ${order?.net_revenue?.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={classes.footerTotalRow}>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${orders?.map(order => order.revenue).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${orders?.map(order => order.discount).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${orders?.map(order => order.cost_of_goods).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${orders?.map(order => order.cost_cancels).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${orders?.map(order => order.lease_provider_charge).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${orders?.map(order => order.bot_tax).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  {/*<TableCell*/}
                  {/*  align="right"*/}
                  {/*  className={classes.footerTotal}*/}
                  {/*>*/}
                  {/*  ${orders?.map(order => order.bb_tax).reduce((a, b) => a + b, 0.0).toFixed(2)}*/}
                  {/*</TableCell>*/}
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${orders?.map(order => order.net_revenue).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          {/*</PerfectScrollbar>*/}
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {ordersCount} Records found
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired
};

Results.defaultProps = {
  orders: []
};

export default Results;


