import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Typography,/* CardActions, IconButton, Collapse*/
} from '@material-ui/core';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  address: {
    textTransform: 'capitalize',
    overflowWrap: 'anywhere',
  },
  mainText: {
    color: colors.blue[900],
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
  columnTable: {
    width: '30%'
  },
  data: {
    overflowWrap: 'anywhere',
  }
}));

export const CustomerProgramInfo = props => {

  const { customer, className, /* isEditMode,*/ ...rest } = props;

  const classes = useStyles();

  // const [expanded, setExpanded] = React.useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {/*<CardHeader*/}
      {/*  action={<CardActions disableSpacing>*/}
      {/*    <IconButton*/}
      {/*      className={clsx(classes.expand, {*/}
      {/*        [classes.expandOpen]: expanded,*/}
      {/*      })}*/}
      {/*      onClick={handleExpandClick}*/}
      {/*      aria-expanded={expanded}*/}
      {/*      aria-label="show more"*/}
      {/*    >*/}
      {/*      <ExpandMoreIcon />*/}
      {/*    </IconButton>*/}
      {/*  </CardActions>} title="Program"*/}
      {/*/>*/}
      {/*<Divider />*/}

      {/*<Collapse in={expanded} timeout="auto" collapsedHeight={150}>*/}
      {/*  <CardContent className={classes.content}>*/}
      <CardHeader title="Program" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            {(customer?.program?.oid || customer?.program?.oid === 0) && <TableRow  className={classes.tableSelected}>
              <TableCell className={classes.columnTable} >
                <Typography variant="subtitle2">
                  OID
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer?.program?.oid}</div>
              </TableCell>
            </TableRow>}
            {(customer?.program?.pid || customer?.program?.pid === 0) && <TableRow >
              <TableCell >
                <Typography variant="subtitle2">
                  PID
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer?.program?.pid}</div>
              </TableCell>
            </TableRow>}

            {(customer?.program?.sid || customer?.program?.sid === 0) && <TableRow  className={classes.tableSelected}>
              <TableCell>
                <Typography variant="subtitle2">
                  SID
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer?.program?.sid}</div>
              </TableCell>
            </TableRow>}

            {customer?.program?.eid && <TableRow >
              <TableCell>
                <Typography variant="subtitle2">
                  EID
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.data}>{customer?.program?.eid || ''}</div>
              </TableCell>
            </TableRow>}

            {customer?.program?.uid && <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography variant="subtitle2">
                  UID
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.data}>{customer?.program?.uid || ''}</div>
              </TableCell>
            </TableRow>}

            {(customer?.click_id || customer?.click_id === 0)&& <TableRow  >
              <TableCell>
                <Typography variant="subtitle2">
                  Click&nbsp;ID
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.data}>{customer?.click_id}</div>
              </TableCell>
            </TableRow>}

            {customer?.gclid && <TableRow className={classes.tableSelected} >
              <TableCell>
                <Typography variant="subtitle2">
                  GCLID
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.data}>{customer?.gclid || ''}</div>
              </TableCell>
            </TableRow>}

            {customer?.fbclid && <TableRow >
              <TableCell>
                <Typography variant="subtitle2">
                  FBCLID
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.data}>{customer?.fbclid || ''}</div>
              </TableCell>
            </TableRow>}

          </TableBody>
        </Table>
      </CardContent>
      {/*</Collapse>*/}
      {/*</CardContent>*/}
    </Card>
  );
};

CustomerProgramInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

